import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import PromoBlurb from '../PromoBlurb'

const PleoHeader = ({ featured }: { featured?: boolean }) => {
  const data = useStaticQuery(query)
  const photo = data.hero.childImageSharp.gatsbyImageData
  return (
    <PromoBlurb
      photo={photo}
      company="Pleo"
      heading="Pleo empowers engineers to deliver customer value faster"
      subheading="Pleo, one of the fastest growing SaaS/fintech companies in Europe, uses a combination of Linear, GitHub, and Swarmia to empower their engineering teams with visibility, consistency, and healthy developer insights."
      featured={featured}
    />
  )
}

export default PleoHeader

const query = graphql`
  {
    hero: file(relativePath: { eq: "images/story/pleo/hero.png" }) {
      childImageSharp {
        gatsbyImageData(width: 480, height: 540, layout: FIXED)
      }
    }
  }
`
