import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import MetaImage from '../assets/images/meta/linear.png'
import Layout from '../components/Layout'
import SlackIcon from '../assets/images/product/icons/slack.svg'
import CTABlock from '../components/blocks/CTABlock'
import ColumnsBlock, { gridSpacing } from '../components/blocks/ColumnsBlock'
import { DarkLogoBlock } from '../components/blocks/DarkLogoBlock'
import HeroBlock from '../components/blocks/HeroBlock'
import IllustrationBlock from '../components/blocks/IllustrationBlock'
import LeadBlock from '../components/blocks/LeadBlock'
import MosaicBlock from '../components/blocks/MosaicBlock'
import QuoteBlock from '../components/blocks/QuoteBlock'
import { FeaturedBlogArticles } from '../components/changelog/FeaturedBlogArticles'
import Block from '../components/primitives/Block'
import Stack from '../components/primitives/Stack'
import Box from '../components/primitives/Box'
import Row from '../components/primitives/Row'
import Blurb from '../components/system/Blurb'
import ShadowFrame from '../components/primitives/ShadowFrame'
import { responsiveScale } from '../styles/helpers'
import PleoPromo from '../components/story/pleo/Promo'
import PleoResults from '../components/story/pleo/Results'

export const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "images/product/insights/hero-bg.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 99)
      }
    }
    lead1: file(relativePath: { eq: "images/lead/initiative.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1200)
      }
    }
    lead2: file(relativePath: { eq: "images/lead/sprints.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1200)
      }
    }
    blurb1: file(
      relativePath: { eq: "images/blurbs/initiative-listing-736.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 736)
      }
    }
    blurb2: file(
      relativePath: { eq: "images/blurbs/initiative-forecasts-736.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 736)
      }
    }
    mosaicLeft: file(relativePath: { eq: "images/mosaic/work-log-kard.png" }) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicRight: file(
      relativePath: { eq: "images/mosaic/issue-popup-kard.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    overview: file(relativePath: { eq: "images/enterprise/overview.png" }) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    integrations: file(
      relativePath: { eq: "images/enterprise/integrations.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    organizationalInsights: file(
      relativePath: {
        eq: "images/product/overview/organizational-insights.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 632)
      }
    }
    josep: file(relativePath: { eq: "images/quotes/josep.png" }) {
      childImageSharp {
        gatsbyImageData(width: 64)
      }
    }
    surveys: file(relativePath: { eq: "images/product/overview/surveys.png" }) {
      childImageSharp {
        gatsbyImageData(width: 640)
      }
    }
    workingAgreements: file(
      relativePath: { eq: "images/product/overview/working-agreements.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 330)
      }
    }
    lead: file(
      relativePath: { eq: "images/product/software-capitalization/lead.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1200)
      }
    }
    ciCdInsights: file(
      relativePath: { eq: "images/product/overview/ci-cd-insights.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 736)
      }
    }
  }
`

const LinearPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      title="Linear reporting for complete engineering insights"
      variant="dark"
      isNew
      description="Visualize data from Linear and combine it with metrics from your GitHub to gain full visibility into engineering work."
      metaImage={MetaImage}
    >
      <HeroBlock
        title={<>Linear reporting for complete engineering insights</>}
        content="Visualize data from Linear and combine it with metrics from your GitHub to gain full visibility into engineering work."
        backgroundImage={getImage(data.backgroundImage)!}
      />
      <DarkLogoBlock />
      <LeadBlock
        heading="Follow the progress of engineering initiatives"
        content="Swarmia makes it easy to keep tabs on the status of your cross-team initiatives. View all projects at once or drill down to individual issues."
      />
      <Box>
        <IllustrationBlock
          paddingTop={responsiveScale(32)}
          image={getImage(data.lead1)!}
          noPlaceHolder
          rounded
          shadow
        />
      </Box>
      <ColumnsBlock
        paddingTop={responsiveScale(64)}
        paddingBottom={responsiveScale(92)}
        columns={[
          <Blurb
            key="1"
            title="See all initiatives at a glance"
            text="Keep track of all ongoing projects to proactively unblock at-risk initiatives."
            image={getImage(data.blurb1)}
            isLarge
          />,
          <Blurb
            key="2"
            title="Forecast end dates"
            text="Estimate the end date of each initiative by using data from past projects."
            image={getImage(data.blurb2)}
            isLarge
          />,
        ]}
      />
      <LeadBlock
        heading="Give teams the tools they need to eliminate common antipatterns"
        content="Swarmia gives teams and managers visibility into all the ongoing work. With it, you’ll be able to start better conversations and fix bottlenecks before they snowball into huge problems."
      />
      <MosaicBlock
        title="Find and fix bottlenecks"
        content="Siloing, too much work in progress, or changing priorities? Swarmia helps you spot problems so you can take action to solve them. "
        image={getImage(data.mosaicLeft)!}
      />
      <MosaicBlock
        title="Learn from past work"
        content="Use Swarmia in team retrospectives to analyze flow efficiency and scope creep to take the learnings from every finished project."
        image={getImage(data.mosaicRight)!}
        imageAlign="right"
      />
      <Box
        // This is needed for the FAQ styles to work
        className="main-body-old-styles"
        paddingTop={responsiveScale(64)}
      >
        <PleoPromo />
        <PleoResults />
      </Box>
      <QuoteBlock person="martin2" />
      <CTABlock title="Ready for a better way to do Linear&nbsp;GitHub&nbsp;reporting?" />
      <LeadBlock
        heading="Create audit-ready software development cost reports"
        content={
          <>
            Combine data from Linear and GitHub and track capitalizable software
            development work. Blend with cost data to create audit-ready
            reports.
          </>
        }
      />
      <Box>
        <IllustrationBlock
          paddingTop={responsiveScale(32)}
          paddingBottom={responsiveScale(32)}
          image={getImage(data.lead)!}
          noPlaceHolder
          rounded
          shadow
        />
      </Box>
      <QuoteBlock person="alex" />
      <LeadBlock
        heading="Metrics alone don’t drive change"
        content="Combine Linear insights with other Swarmia tools for a full picture of business outcomes, developer productivity, and developer experience."
      />
      <Block
        paddingTop={responsiveScale(64)}
        paddingBottom={responsiveScale(64)}
      >
        <Row
          maxWidth="largeBlock"
          space={gridSpacing}
          justifyContent="center"
          css={{ margin: 'auto' }}
          flexDirection={{ xs: 'column', md: 'row' }}
        >
          <Box flex={1}>
            <Stack key="1" space={gridSpacing}>
              <Blurb
                title="Developer surveys"
                text="Run targeted developer surveys to complement system metrics with feedback from your engineers. "
                href="/product/surveys/"
                target="_self"
                image={getImage(data.surveys)}
                isLarge
              />
              <JosepQuote />
            </Stack>
          </Box>
          <Box flex={1}>
            <Stack key="2" space={gridSpacing}>
              <Row
                width="100%"
                space={gridSpacing}
                flexDirection={{
                  xs: 'column',
                  sm: 'row',
                  md: 'column',
                  lg: 'row',
                }}
              >
                <Box flex={1}>
                  <Blurb
                    title="Working agreements"
                    text="Put continuous improvement on autopilot with team-wide working agreements."
                    href="/product/working-agreements"
                    target="_self"
                    image={getImage(data.workingAgreements)}
                    clipImage
                  />
                </Box>
                <Box flex={1}>
                  <Blurb
                    icon={SlackIcon}
                    title="Slack notifications"
                    text="Automate code review reminders and stay on top of ongoing work with Swarmia’s two-way GitHub-Slack integration."
                    href="/github-slack-integration/"
                    target="_self"
                  />
                </Box>
              </Row>
              <Blurb
                title="CI insights"
                text="Dramatically reduce CI wait times and costs with detailed CI insights."
                href="/product/ci-cd-insights/"
                target="_self"
                image={getImage(data.ciCdInsights)}
                imageAlign="right"
                isLarge
                clipImage
              />
            </Stack>
          </Box>
        </Row>
      </Block>
      <Box paddingBottom={responsiveScale(64)}>
        <CTABlock title="Get started with Swarmia today." />
        <FeaturedBlogArticles
          heading="More from the swarmia blog"
          articles={[
            '/blog/cross-team-initiatives/',
            '/blog/measuring-software-development-productivity/',
            '/blog/balancing-engineering-investments/',
          ]}
          newStyles
        />
      </Box>
    </Layout>
  )
}

export default LinearPage

const JosepQuote = () => {
  const { josep } = useStaticQuery(query)

  return (
    <ShadowFrame
      paddingTop={40}
      paddingRight={40}
      paddingBottom={32}
      paddingLeft={40}
    >
      <Stack space={20}>
        <Box
          fontFamily="FactorA"
          fontSize={24}
          lineHeight="150%"
          fontWeight={700}
          maxWidth={500}
        >
          “Swarmia’s GitHub Slack integration is one of the reasons our
          engineers really love the product.”
        </Box>
        <Row space={16} alignItems="center">
          <GatsbyImage image={getImage(josep)!} alt="Josep Jaume Rey" />
          <Stack>
            <Box font="small" fontWeight={700}>
              Josep Jaume Rey
            </Box>
            <Box font="small">Senior Engineering Director at Factorial</Box>
          </Stack>
        </Row>
      </Stack>
    </ShadowFrame>
  )
}
