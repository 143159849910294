import React from 'react'
import Results from '../Results'

const PleoResults = () => {
  return (
    <Results
      results={[
        { name: 'cycle time', value: 53, dir: 'down' },
        { name: 'review time', value: 43, dir: 'down' },
        { name: 'merge time', value: 42, dir: 'down' },
      ]}
    />
  )
}
export default PleoResults
